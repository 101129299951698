<template>
  <div id="app">
    <MNotification />
    <OHeader />
    <transition
      name="router-anim"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <router-view class="min-height" />
    </transition>
    <OFooter />
  </div>
</template>

<script>
import OHeader from "@/components/organisms/o-header.vue";
import OFooter from "@/components/organisms/o-footer.vue";
import MNotification from "@/components/molecules/m-notification.vue";

export default {
  name: "MrJeff",
  metaInfo() {
    return {
      title: this.$t("Bine ai venit!"),
      titleTemplate: "%s | Mr Jeff Brașov",
    };
  },
  components: {
    OHeader,
    OFooter,
    MNotification,
  },
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

body {
  margin: 0;
  font-family: var(--font-family-secondary);
  padding-top: var(--h-header);
  background-color: var(--c-light);
}
#app {
  --h-header: 142px;
  --h-footer: 112px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::selection {
    color: var(--c-white);
    background: var(--c-primary-darken);
  }
}
.min-height {
  min-height: calc(100vh - var(--h-footer) - var(--h-header));
}
.container {
  position: relative;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacer-sm);

  @include for-mobile {
    padding: 0;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import i18n from "../i18n";

const Home = () =>
  import(/* webpackChunkName: "vsf-home" */ "../views/Home.vue");
const About = () =>
  import(/* webpackChunkName: "vsf-about" */ "../views/About.vue");
const ServicesAndPrices = () =>
  import(
    /* webpackChunkName: "vsf-services-and-prices" */ "../views/ServicesAndPrices.vue"
  );
const Contact = () =>
  import(/* webpackChunkName: "vsf-contact" */ "../views/Contact.vue");

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      { name: "Home", path: "/", component: Home },
      { name: "About", path: "despre-noi", component: About },
      {
        name: "Services",
        path: "servicii-preturi",
        component: ServicesAndPrices,
      },
      { name: "Contact", path: "contact", component: Contact },
      // {
      //   name: "MyAccount",
      //   path: "my-account",
      //   component: MyAccount,
      //   beforeEnter: (to, from, next) => {
      //     if (localStorage.getItem("token")) {
      //       next();
      //     } else {
      //       next(`/${i18n.locale}/login`);
      //     }
      //   },
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;

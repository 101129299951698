<template>
  <div>
    <div
      class="o-header__topbar"
      :class="{ 'o-header__topbar--full': !isScrolledLazy }"
    >
      <div class="o-header__topbar--inner">
        <div class="o-header__topbar--item">
          <SfIcon icon="phone_2" class="o-header__topbar--item-icon" />
          <a href="tel:40756724674">
            {{ topbar.phone }}
          </a>
        </div>
        <div class="o-header__topbar--item">
          <SfIcon icon="whatsapp" class="o-header__topbar--item-icon" />
          <a
            href="https://wa.me/40756724674?text=Buna%20ziua,%20as%20dori%20sa%20fac%20o%20comanda."
            target="_blank"
          >
            {{ $t("Scrie-ne pe WhatsApp") }}
          </a>
        </div>
        <div class="o-header__topbar--item desktop-only">
          <button
            class="o-header__topbar--item-flag"
            :class="{
              'o-header__topbar--item-flag-active': $route.params.lang === 'ro',
            }"
            @click="setLocale('ro')"
          >
            <img src="@/assets/flags/ro.svg" alt="Romania Flag" />
          </button>
          <button
            class="o-header__topbar--item-flag"
            :class="{
              'o-header__topbar--item-flag-active': $route.params.lang === 'en',
            }"
            @click="setLocale('en')"
          >
            <img src="@/assets/flags/uk.svg" alt="United Kingdom Flag" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="o-header"
      :class="{
        'o-header__mini': isScrolledLazy,
        'o-header__hide': isScrolled && scrollDirection == 'down',
      }"
    >
      <div
        class="o-header__container"
        :class="{ 'o-header__container--full': !isScrolledLazy }"
      >
        <aLogo :logo="renderLogo" class="logo" />

        <div class="menu desktop-only">
          <span
            v-for="(item, i) in menu"
            :key="`${item.route_name}-${i}`"
            class="menu__item"
            :class="{ 'menu__item--active': isCurrentPath(item.route_name) }"
            @click="setRoute(item.route_name)"
          >
            {{ $t(item.title) }}
          </span>
        </div>
        <div class="menu__mobile--icon mobile-only" @click="toggleMenu">
          <SfIcon :icon="'burger'" size="2rem" />
        </div>
      </div>
    </div>
    <div
      class="menu__mobile mobile-only"
      ref="menuMobile"
      :class="{ 'menu__mobile--active': isMenuOpen }"
    >
      <div class="menu__mobile--main">
        <div class="menu__mobile--topbar">
          <div class="menu__mobile--topbar--inner">
            <div class="menu__mobile--topbar--item">
              <SfIcon icon="phone_2" class="menu__mobile--topbar--item-icon" />
              <a href="tel:40756724674">
                {{ topbar.phone }}
              </a>
            </div>
            <div class="menu__mobile--topbar--item">
              <SfIcon icon="whatsapp" class="menu__mobile--topbar--item-icon" />
              <a
                href="https://wa.me/40756724674?text=Buna%20ziua,%20as%20dori%20sa%20fac%20o%20comanda."
                target="_blank"
              >
                {{ $t("Scrie-ne pe WhatsApp") }}
              </a>
            </div>
          </div>
        </div>
        <div class="menu__mobile--header">
          <aLogo :logo="'default'" class="logo" />
          <div class="menu__mobile--icon mobile-only" @click="toggleMenu">
            <SfIcon :icon="'close'" size="2rem" />
          </div>
        </div>
      </div>
      <div class="menu__mobile--item-wrapper">
        <span
          v-for="(item, i) in menu"
          :key="`${item.route_name}-${i}`"
          class="menu__mobile--item"
          @click="setRoute(item.route_name)"
        >
          {{ $t(item.title) }}
        </span>
      </div>
      <div class="menu__mobile--lang">
        <button
          class="menu__mobile--lang-flag"
          :class="{
            'menu__mobile--lang-flag-active': $route.params.lang === 'ro',
          }"
          @click="setLocale('ro')"
        >
          <img src="@/assets/flags/ro.svg" alt="Romania Flag" />
          {{ $t("Română") }}
        </button>
        <button
          class="menu__mobile--lang-flag"
          :class="{
            'menu__mobile--lang-flag-active': $route.params.lang === 'en',
          }"
          @click="setLocale('en')"
        >
          <img src="@/assets/flags/uk.svg" alt="United Kingdom Flag" />
          {{ $t("English") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import aLogo from "../atoms/a-logo.vue";
import { SfIcon } from "@storefront-ui/vue";

export default {
  components: {
    aLogo,
    SfIcon,
  },
  data() {
    return {
      isMenuOpen: false,
      topbar: {
        phone: "0756 724 674",
      },
      menu: [
        { title: "Acasă", route_name: "Home" },
        { title: "Despre noi", route_name: "About" },
        { title: "Servicii și Prețuri", route_name: "Services" },
        { title: "Contact", route_name: "Contact" },
      ],
      headerHeight: 123,
      scrollPosition: 0,
      lastScrollPosition: 0,
      scrollDirection: "",
      isScrolledLazy: false,
    };
  },
  created() {
    this.debouncedHandleScroll = this.debounce(this.handleScroll, 0);
  },
  mounted() {
    window.addEventListener("scroll", this.debouncedHandleScroll);
    window.addEventListener("beforeunload", this.removeClickOutsideListener);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.debouncedHandleScroll);
    this.removeClickOutsideListener();
  },
  watch: {
    isScrolled() {
      setTimeout(() => {
        this.isScrolledLazy = this.isScrolled;
      }, 400);
    },
  },
  computed: {
    isScrolled() {
      return this.scrollPosition > this.headerHeight;
    },
    renderLogo() {
      if (this.isScrolledLazy) {
        return "default";
      } else {
        return "logo_w";
      }
    },
  },
  methods: {
    clickOutsideMenu(e) {
      const element = this.$refs.menuMobile;
      if (!element.contains(e.target)) {
        this.toggleMenu();
      }
    },
    toggleMenu(e) {
      if (e) {
        e.stopPropagation();
      }
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        this.addClickOutsideListener();
      } else {
        this.removeClickOutsideListener();
      }
    },
    addClickOutsideListener() {
      document.addEventListener("click", this.clickOutsideMenu);
    },
    removeClickOutsideListener() {
      document.removeEventListener("click", this.clickOutsideMenu);
    },
    handleScroll() {
      this.scrollPosition = window.scrollY || window.pageYOffset;
      if (this.scrollPosition > this.lastScrollPosition) {
        this.scrollDirection = "down";
      } else if (this.scrollPosition < this.lastScrollPosition) {
        this.scrollDirection = "up";
      }
      this.lastScrollPosition = this.scrollPosition;
    },
    isCurrentPath(routeName) {
      return this.$route.name === routeName;
    },
    setRoute(routeName) {
      this.$router.push({ name: routeName });
      this.toggleMenu();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({ params: { lang: locale } });
      this.toggleMenu();
    },
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: 0.6s ease;
  &__topbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    height: 35px;
    background-color: var(--c-primary-darken);
    transition: 0.3s ease;
    &--full {
      background-color: transparent;
      height: 40px;
      .o-header__topbar--item {
        padding: var(--spacer-sm) var(--spacer-xs) 0;
      }
    }
    &--inner {
      max-width: 1250px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include for-mobile {
        justify-content: center;
      }
    }
    &--item {
      @include for-mobile {
        padding: 0 var(--spacer-xs);
      }
      display: flex;
      align-items: center;
      padding: 0 var(--spacer-sm);
      transition: 0.3s ease;
      &-icon {
        --icon-color: var(--c-white);
        --icon-size: 1rem;
        margin-right: var(--spacer-2xs);
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--c-white);
        font-size: var(--font-sm);
        font-family: var(--font-family-primary);
        font-weight: var(--font-semibold);
        letter-spacing: 0.5px;
        line-height: 1.2;
        &:hover {
          text-decoration: underline;
        }
      }
      &-flag {
        display: flex;
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        opacity: 0.6;
        cursor: pointer;
        &:first-child {
          padding-right: var(--spacer-xs);
          border-right: 1px solid var(--c-white);
        }
        &:last-child {
          padding-left: var(--spacer-xs);
        }
        img {
          width: 1.125rem;
          height: 1.125rem;
        }
        &-active,
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1250px;
    margin: 0 auto;
    height: 91px;
    padding: calc(var(--spacer-sm) + 35px) var(--spacer-sm) var(--spacer-sm);
    transition: 0.6s ease;
    &--full {
      --icon-color: var(--c-white);
      padding: 35px var(--spacer-sm) var(--spacer-sm);
    }
    @include for-mobile {
      position: relative;
    }
  }
  &__mini {
    --icon-color: var(--c-dark);
    background-color: white;
    filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));

    .o-header__container {
      height: 40px;
    }
    .logo {
      width: 90px !important;
    }
    .menu__item {
      color: var(--c-dark) !important;
      &::before {
        background-color: var(--c-dark) !important;
      }
    }
  }
  &__hide {
    transform: translate(0, calc(var(--h-header) * -1));
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacer-sm);

  &__item {
    display: block;
    font-size: var(--font-base);
    font-weight: var(--font-medium);
    cursor: pointer;
    transition: all 0.15s ease;
    color: var(--c-white);
    letter-spacing: 0.3px;
    text-transform: uppercase;
    opacity: 0.75;
    padding: var(--spacer-sm);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(70% - var(--spacer-sm) * 2);
      height: 2px;
      background-color: var(--c-white);
      opacity: 0;
      transition: all 0.3s ease;
    }
    &--active,
    &:hover {
      opacity: 1;
      &::before {
        opacity: 1;
      }
    }
  }
  &__mobile {
    background-color: var(--c-white);
    position: fixed;
    top: -30rem;
    left: 0;
    width: 100%;
    height: 30rem;
    z-index: 1001;
    transition: 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.1));
    &--lang {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-lg);

      &-flag {
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        border-radius: 8px;
        background-color: #dadada;
        padding: var(--spacer-xs) var(--spacer-lg);
        font-size: var(--font-sm);
        font-weight: var(--font-medium);
        font-family: var(--font-family-primary);
        color: var(--c-dark);
        &-active {
          background-color: var(--c-primary-darken);
          color: var(--c-white);
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: var(--spacer-xs);
        }
      }
    }
    &--topbar {
      width: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      height: 35px;
      background-color: var(--c-primary-darken);
      &--full {
        background-color: transparent;
        height: 40px;
        .o-header__topbar--item {
          padding: var(--spacer-sm) var(--spacer-sm) 0;
        }
      }
      &--inner {
        max-width: 1250px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--item {
        @include for-mobile {
          padding: 0 var(--spacer-xs);
        }
        display: flex;
        align-items: center;
        padding: 0 var(--spacer-sm);
        transition: 0.3s ease;
        &-icon {
          --icon-color: var(--c-white);
          --icon-size: 1rem;
          margin-right: var(--spacer-2xs);
        }
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: var(--c-white);
          font-size: var(--font-sm);
          font-family: var(--font-family-primary);
          font-weight: var(--font-semibold);
          letter-spacing: 0.5px;
          line-height: 1.2;
          &:hover {
            text-decoration: underline;
          }
        }
        &-flag {
          display: flex;
          background: transparent;
          outline: none;
          border: none;
          padding: 0;
          opacity: 0.6;
          cursor: pointer;
          &:first-child {
            padding-right: var(--spacer-xs);
            border-right: 1px solid var(--c-white);
          }
          &:last-child {
            padding-left: var(--spacer-xs);
          }
          img {
            width: 1.125rem;
            height: 1.125rem;
          }
          &-active,
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    &--header {
      --icon-color: var(--c-dark);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: var(--spacer-sm);
    }
    &--icon {
      // padding: var(--spacer-xs);
    }
    &--item {
      display: block;
      font-size: var(--font-base);
      font-weight: var(--font-medium);
      transition: all 0.15s ease;
      color: var(--c-dark);
      letter-spacing: 0.3px;
      text-transform: uppercase;
      padding: var(--spacer-sm);
      position: relative;
    }
    &--active {
      top: 0;
    }
    .logo {
      width: 90px !important;
    }
  }
}
</style>

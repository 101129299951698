<template>
  <div class="o-footer">
    <div class="footer__main">
      <div class="logo mobile-only">
        <img src="@/assets/logos/logo_w.png" />
      </div>
      <div class="links">
        <div v-for="(link, i) in links" :key="i" class="links__item">
          <span @click="goTo(link.link)">{{ $t(link.name) }}</span>
        </div>
      </div>
      <div class="logo desktop-only">
        <aLogo logo="logo_w" />
      </div>
      <div class="social">
        <!-- <div class="links__item">
          <span @click="goTo('/privacy-policy')">
            {{ $t("Politică de Confidențialitate") }}
          </span>
        </div> -->
        <div
          v-for="(item, i) in social"
          :key="i"
          class="social__icon"
          @click="openExternal(`https://${item.link}`)"
        >
          <img :src="item.icon" :alt="item.name" />
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <span class="copyright">
        {{
          `Copyright &copy; ${getCurrentYear} MrJeffBrasov.ro &mdash;
          ${$t("Toate drepturile rezervate")}`
        }}
      </span>
      <div class="creators">
        <span>
          {{ $t("Website creat de ") }}
          <a href="https://www.linkedin.com/in/laurentiu-tutu/" target="_blank">
            Laurențiu Țuțu
          </a>
        </span>
        <span>
          {{ $t("Imagini furnizate de ") }}
          <a href="https://www.freepik.com" target="_blank">Freepik</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import aLogo from "../atoms/a-logo.vue";

export default {
  components: {
    aLogo,
  },
  data() {
    return {
      links: [
        {
          name: "Acasă",
          link: "/",
        },
        {
          name: "Despre Noi",
          link: "/despre-noi",
        },
        {
          name: "Servicii și Prețuri",
          link: "/servicii-preturi",
        },
        {
          name: "Contact",
          link: "/contact",
        },
      ],
      social: [
        {
          name: "facebook",
          icon: require("@/assets/icons/social/facebook.png"),
          link: "facebook.com/jeffbrasov",
        },
        {
          name: "instagram",
          icon: require("@/assets/icons/social/instagram.png"),
          link: "instagram.com/mrjeff.brasov",
        },
        {
          name: "whatsapp",
          icon: require("@/assets/icons/social/whatsapp.png"),
          link: "wa.me/40756724674",
        },
      ],
    };
  },
  computed: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    goTo(link, hash) {
      const toRoute = `/${this.$i18n.locale}${link}`;
      if (hash) {
        this.$router.push({ name: link, hash: hash });
        return;
      } else {
        this.$router.push(toRoute);
        return;
      }
    },
    openExternal(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-footer {
  background: rgba(0, 25, 41, 1);
  .footer {
    &__main {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 10rem);
      padding: var(--spacer-base) var(--spacer-2xl);
      @include for-desktop {
        height: 4rem;
      }
      @include for-mobile {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--spacer-base) var(--spacer-sm);
        width: calc(100% - 2rem);
        align-items: center;
      }
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
.links {
  display: flex;
  flex: 1 1 30%;
  @include for-mobile {
    justify-content: center;
  }
  &__item {
    display: flex;
    padding: 0.65rem 1rem;
    span {
      @include for-mobile {
        font-size: var(--font-xs);
      }
      align-self: center;
      font-size: var(--font-sm);
      font-weight: var(--font-medium);
      font-family: var(--font-family-secondary);
      color: var(--c-white);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.logo {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 40%;
  max-width: 6rem;
  height: 100%;
  img {
    display: block;
    width: 100%;
    cursor: pointer;
    overflow-clip-margin: unset;
    @include for-mobile {
      margin: 0 0 var(--spacer-sm);
    }
  }
}

.social {
  display: flex;
  align-items: center;
  flex: 1 1 30%;
  justify-content: flex-end;
  @include for-mobile {
    justify-content: center;
    margin-top: var(--spacer-sm);
  }
  &__icon {
    margin: 0.65rem 1rem;
    position: relative;
    width: 1rem;
    height: 1rem;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: invert(1);
      cursor: pointer;

      &:hover {
        filter: invert(38%) sepia(66%) saturate(3288%) hue-rotate(181deg)
          brightness(87%) contrast(100%);
      }
    }
  }
}
.copyright {
  color: var(--c-white);
  font-size: var(--font-xs);
  font-weight: var(--font-light);
  padding: var(--spacer-base) var(--spacer-3xl) var(--spacer-xs);
  border-top: 1px solid var(--c-light);
  @include for-mobile {
    padding: var(--spacer-base) var(--spacer-xs) var(--spacer-xs);
  }
}
.creators {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 var(--spacer-base);
  span {
    color: var(--c-white);
    font-size: var(--font-xs);
    font-weight: var(--font-light);
    padding: var(--spacer-xs) var(--spacer-xl) 0;
    @include for-mobile {
      max-width: 8rem;
      text-align: center;
    }
  }
  a {
    color: var(--c-primary-lighten);
    font-size: var(--font-xs);
    font-weight: var(--font-light);
    text-decoration: none;
    &:hover {
      color: var(--c-primary-darken);
      text-decoration: underline;
    }
  }
}
</style>
